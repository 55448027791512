import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
    en: {
        //Header
        header_title: `Software Developer`,
        header_home: `Home`,
        header_about: `About Me`,
        header_communication: `Contact`,
        header_projects: `Projects`,

        //Hero
        hero_title: "Hello, I'm Eyyüp Ulutaş",
        hero_subtitle: `I'm a software developer creating (sometimes designing) software for mobile and web applications.`,
        hero_projects: `PROJECTS`,

        //About
        about_title: "ABOUT ME",
        about_subtitle: "In this section, you'll find what I do and my current skills mostly in terms of programming and technology.",
        about_know_me: "Get to Know Me!",
        about_me_1: "Hello, I'm Eyyüp. I am currently a 3rd-year student in the Management Information Systems program at Anadolu University.",
        about_me_2: "I've been passionate about technology since my early school years and have been deeply involved in software for around 6 years. Since 2019, I have been focusing on mobile app development.",
        about_me_3: "I also work on web development, robotics coding, and back-end projects to keep myself updated. If you'd like to work with me or ask any questions, feel free to reach out through the contact section.",
        about_communication: `Contact`,
        about_abilities_interests: "Skills & Interests",

        //Projects
        projects_text: "PROJECTS",
        projects_subtitle: "In this section, I will share information about some of the projects I have developed and the technologies I used to build them.",
        projects_tabs: {
            all: "All",
            web: "Web",
            mobile: "Mobile"
        },

        projects: {
            web: {
                projects: {
                    project_netflix: {
                        title: "Netflix UI Clone",
                        description: "This project is a simplified UI (User Interface) clone of Netflix."
                    },
                    project_challange_snake: {
                        title: "Challenge Snake",
                        description: "This project was developed for Selman Kahya's competition. You can click the YouTube button to see the review of the game."
                    },
                    project_bolita_coming: {
                        title: "Bolita Candle Coming Soon",
                        description: "This project is the coming soon screen for a startup that sells custom candles."
                    },
                    project_bolita: {
                        title: "Bolita Candle",
                        description: "This project is for a startup selling custom candles. I developed the UI, Back-end, and Admin Panel sections."
                    },
                    project_elrond_cyber: {
                        title: "Elrond Cyber",
                        description: "This project is a website explaining the details and future plans of the eCyber NFT collection."
                    },
                    project_starbucks: {
                        title: "Starbucks UI Clone",
                        description: "This project is a UI (User Interface) clone of Starbucks."
                    },
                    project_cebimdeki_dukkan: {
                        title: "Cebimdeki Dükkan",
                        description: "This project was developed for Turkey's newest online shopping platform."
                    },
                    project_wash_center: {
                        title: "Wash Center",
                        description: "This project is a card system developed for car washes. Features include price updates and card balance inquiries through an admin panel."
                    },
                    project_kardesler_oto_servis: {
                        title: "Kardeşler Auto Service",
                        description: "This project was developed for a client providing auto repair & towing services."
                    }
                }
            },
            mobile: {
                projects: {
                    project_cebimdeki_dukkan: {
                        title: "Cebimdeki Dükkan - Shopping Platform",
                        description: "This project is a mobile application developed for Turkey's newest online shopping platform."
                    },
                    project_wit: {
                        title: "WiT - Fun Twitter Trivia Game",
                        description: `How about making your Twitter timeline more fun? Log in with your Twitter account and guess who tweeted what! I worked as the front-end developer on this project.`
                    },
                    project_adgain: {
                        title: "AdGain - Watch Ads & Earn Money",
                        description: `This project allows users to earn money by watching ads. I managed and developed the entire process of this project.`
                    },
                    project_meetzone_social: {
                        title: "MeetZone Social",
                        description: `MeetZone Social is an app where new university students can easily find campus clubs and events. Only the interface has been developed.`
                    },
                    project_getir_clone: {
                        title: "Getir Clone",
                        description: `This project is a simplified UI (User Interface) clone of Getir.`
                    },
                    project_restaurant_app: {
                        title: "Restaurant App",
                        description: `This project was developed as a graduation project for the Isparta University of Applied Sciences Computer Programming Department. Both front-end and back-end were developed.`
                    }
                }
            }
        },

        //Contact
        contact_title: "CONTACT",
        contact_subtitle: "If you have any questions or just want to say hello, I'll do my best to get back to you!",
        contact_say_hello: "Say Hello!",

        //Footer
        footer_social_media: "Social Media",
    },
    tr: {
        //Header
        header_title: `Yazılım Geliştirici`,
        header_home: `Anasayfa`,
        header_about: `Hakkımda`,
        header_communication: `İletişim`,
        header_projects: `Projeler`,

        //Hero
        hero_title: "Merhaba, Ben Eyyüp Ulutaş",
        hero_subtitle: `Mobil ve Web uygulamalar için yazılım (bazen tasarım) geliştiren bir yazılım geliştiricisiyim`,
        hero_projects: `PROJELER`,

        //About
        about_title: "HAKKIMDA",
        about_subtitle: " Bu alanda, ne yaptığım ve çoğunlukla programlama ve teknoloji açısından mevcut becerilerim",
        about_know_me: "Beni Tanıyın !",
        about_me_1: "Merhaba ben Eyyüp. Şu anda Anadolu Üniversitesi Yönetim Bilişim Sistemleri 3. Sınıf öğrencisiyim.",
        about_me_2: "İlkokul zamanlarımdan beri teknolojiye meraklı birisiyim, yaklaşık olarak 6 senedir yazılım ile iç içeyim 2019 yılından beri mobil uygulama geliştirme üzerine çalışmalar yapmaktayım.",
        about_me_3: "Aynı zamanda web geliştirme, robotik kodlama ve back-end alanlarında projeler yaparak kendimi güncel tutmaya çalışıyorum. Eğer sende benimle çalışmak veya soru sormak istersen bana iletişim kısmından ulaşabilirsin.",
        about_communication: `İletişim`,
        about_abilities_interests: "Yetenekler & İlgi Alanları",

        //Projects
        projects_text: "PROJELER",
        projects_subtitle: "Bu alanda, geliştirdiğim bazı projeler ve bu projeleri hangi teknolojiler ile geliştirdiğim konusunda bilgi vereceğim",
        projects_tabs: {
            all: "Tümü",
            web: "Web",
            mobile: "Mobil"
        },

        projects: {
            web: {
                projects: {
                    project_netflix: {
                        title: "Netflix UI Klonu",
                        description: "Bu proje, Netflix'in basitleştirilmiş bir UI(Kullanıcı Arayüzü) klonudur."
                    },
                    project_challange_snake: {
                        title: "Challenge Snake",
                        description: "Bu proje, Selman Kahya'nın yarışması için geliştirilmiş bir oyundur. Oyunun incelendiği videoya gitmek için youtube butonuna basabilirsiniz."
                    },
                    project_bolita_coming: {
                        title: "Bolita Candle Yakında",
                        description: "Bu proje, kişiye özel mum satışı yapan bir girişimin yakında ekranını geliştirdiğim projedir."
                    },
                    project_bolita: {
                        title: "Bolita Candle",
                        description: "Bu proje, kişiye özel mum satışı yapan bir girişimdir. Projede UI, Back-end ve Admin Panel kısımlarını geliştirdim."
                    },
                    project_elrond_cyber: {
                        title: "Elrond Cyber",
                        description: "Bu proje, eCyber NFT koleksiyonunun ayrıntılarını ve gelecek planlarını anlatan bir websitesidir."
                    },
                    project_starbucks: {
                        title: "Starbucks UI Clone",
                        description: "Bu proje, Starbucks'ın bir UI(Kullanıcı Arayüzü) klonudur."
                    },
                    project_cebimdeki_dukkan: {
                        title: "Cebimdeki Dükkan",
                        description: "Bu proje, Türkiye'nin en yeni online alışveriş platformu için yapılmıştır."
                    },
                    project_wash_center: {
                        title: "Wash Center",
                        description: "Bu proje, oto yıkamalar için geliştirilen bakiyeli kart sistemidir. Admin panel üzerinden oto yıkama fiyat güncellemesi ve kart bakiyesi sorgulama gibi özellikler mevcuttur."
                    },
                    project_kardesler_oto_servis: {
                        title: "Kardeşler Oto Servis",
                        description: "Bu proje, Oto Servis & Çekici hizmeti veren bir müşteriye yapılmıştır."
                    }
                }
            },
            mobile: {
                projects: {
                    project_cebimdeki_dukkan: {
                        title: "Cebimdeki Dükkan - Alışveriş Platformu",
                        description: "Bu proje, Türkiye'nin en yeni online alışveriş platformu için geliştirilmiş bir mobil uygulamadır."
                    },
                    project_wit: {
                        title: "WiT - Fun Twitter Trivia Game",
                        description: `Twitter zaman çizelgesini daha eğlenceli hale getirdiğimizi söylesek? Twitter hesabınızla giriş yapın ve tweetleri kimin gönderdiğini tahmin edin! Bu projede front-end developer olarak görev aldım.`
                    },
                    project_adgain: {
                        title: "AdGain - Reklam İzle Para Kazan",
                        description: `Bu proje, Kullanıcıların reklam izleyerek gelir elde etmelerini sağlar. Bu projenin tüm süreçlerini ben yönettim ve geliştirdim.`
                    },
                    project_meetzone_social: {
                        title: "MeetZone Social",
                        description: `MeetZone Social, üniversiteye yeni gelen kişilerin üniversite topluluklarını ve etkinliklerini kolayca bulabileceği bir uygulamadır. Sadece arayüz geliştirilmiştir.`
                    },
                    project_getir_clone: {
                        title: "Getir Clone",
                        description: `Bu proje, Getir'in basitleştirilmiş bir UI(Kullanıcı Arayüzü) klonudur.`
                    },
                    project_restaurant_app: {
                        title: "Restaurant App",
                        description: `Bu proje, ISUBÜ (Isparta Uygulamalı Bilimler Üniversitesi) Bilgisayar Programcılığı Bölümü bitirme projesi dersi için hazırlanmıştır. Front-end ve Back-end olarak geliştirilmiştir.`
                    }
                }
            }
        },


        //Contact
        contact_title: "İLETİŞİM",
        contact_subtitle: "Bir sorunuz varsa veya sadece merhaba demek istiyorsanız, size geri dönmek için elimden gelenin en iyisini yapacağım!",
        contact_say_hello: "Merhaba de !",

        //Footer
        footer_social_media: "Sosyal Medya",
    }
 
});

export default strings;
