import React, { Component } from "react";
import { FiGithub, FiInstagram, FiTwitter, FiLinkedin } from "react-icons/fi";

class Contact extends Component {
  render() {
    return (
      <div className="w-full flex flex-col  py-14  bg-[#020c1b] text-white px-28 text-center mt-10">
        <div className="flex flex-col items-start">
          <div className="mx-2 mb-5 font-semibold text-2xl">Sosyal Medya</div>
          <div className="flex">
            <a target="_blank" href="https://github.com/eyyupulutas">
              <FiGithub className="text-primaryWhite mx-2 text-2xl hover:text-primaryGreen "></FiGithub>
            </a>

            <a target="_blank" href="https://www.instagram.com/eyyupii">
              <FiInstagram className="text-primaryWhite mx-2 text-2xl hover:text-primaryGreen "></FiInstagram>
            </a>
            <a target="_blank" href="https://twitter.com/eyyupii">
              <FiTwitter className="text-primaryWhite mx-2 text-2xl hover:text-primaryGreen"></FiTwitter>
            </a>
            <a target="_blank" href="https://www.linkedin.com/in/eyyup-ulutas">
              <FiLinkedin className="text-primaryWhite mx-2 text-2xl hover:text-primaryGreen"></FiLinkedin>
            </a>
          </div>
        </div>

        <div className="w-full h-[1.5px] bg-primaryWhite/20 rounded-md my-7"></div>
        <div className="text-primaryWhite/80 text-xs">
          &copy; Tüm Hakları Saklıdır 2023. &nbsp;
          <a className="underline text-primaryGreen/80" href="/">
            Eyyüp Ulutaş
          </a>
          &nbsp; tarafından tasarlandı & yapıldı
        </div>
      </div>
    );
  }
}

export default Contact;
