import React, { Component } from "react";
import { FiGithub, FiInstagram, FiTwitter, FiLinkedin } from "react-icons/fi";
import strings from './localization';

class Hero extends Component {
  render() {
    const handleClickScroll = () => {
      
      const element = document.getElementById("projects");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    return (
      <div className="h-screen relative w-full pb-20 flex flex-col justify-center items-center bg-gradient-to-b from-[#020c1b] ">
        <div className="hidden md:flex flex-col absolute left-0">
          <a target="_blank" href="https://github.com/eyyupulutas">
            <FiGithub className="text-primaryWhite m-5 text-2xl hover:text-primaryGreen"></FiGithub>
          </a>

          <a target="_blank" href="https://www.instagram.com/eyyupii">
            <FiInstagram className="text-primaryWhite m-5 text-2xl hover:text-primaryGreen"></FiInstagram>
          </a>
          <a target="_blank" href="https://twitter.com/eyyupii">
            <FiTwitter className="text-primaryWhite m-5 text-2xl hover:text-primaryGreen"></FiTwitter>
          </a>
          <a target="_blank" href="https://www.linkedin.com/in/eyyup-ulutas">
            <FiLinkedin className="text-primaryWhite m-5 text-2xl hover:text-primaryGreen"></FiLinkedin>
          </a>
        </div>
        <div
          id="hero"
          className="flex flex-col items-center justify-center  gap-y-9 pb-[30%] md:pb-0"
        >
          <div className="uppercase font-extrabold text-center text-5xl md:text-[56px] text-primaryWhite">
            {strings.hero_title}
          </div>
          <div className="text-primaryWhite/50 text-center text-lg md:text-xl px-2 xl:mx-[460px] md:px-0">
           {strings.hero_subtitle}
          </div>

          <a
            onClick={handleClickScroll}
            className="uppercase font-semibold text-xl bg-[#66ffdbd7] text-[#020c1b]   flex  items-center justify-center text-center px-14  py-3 rounded-custom hover:ring-4 hover:ring-primaryWhite/20 hover:cursor-pointer transition-all"
          >
            {strings.hero_projects}
          </a>
        </div>
        <div className=" absolute  bottom-16 md:bottom-8">
          <div class="home-hero__mouse-scroll-cont">
            <div class="mouse"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Hero;
