import React, { Component } from "react";
import strings from "./localization";

class Contact extends Component {
  render() {
    return (
      <div
        id="contact"
        className="w-full flex flex-col justify-center items-center py-24  "
      >
        <div className="flex flex-col justify-center items-center gap-y-5 mb-14">
          <div className="uppercase text-primaryWhite text-4xl font-bold tracking-widest">
            {strings.contact_title}
          </div>
          <div className="h-1 w-8 bg-[#66ffdbd7] rounded-lg"></div>
          <div className="text-primaryWhite text-center px-2 md:px-0">
            {strings.contact_subtitle}
          </div>
        </div>

        <a
          href="mailto:iletisim@eyyupulutas.com"
          className="uppercase  hover:ring-4 hover:ring-primaryWhite/20  transition-all font-semibold text-lg bg-[#66ffdbd7] text-[#020c1b]   flex  items-center justify-center text-center px-10  py-2.5 rounded-custom "
        >
          {strings.contact_say_hello}
        </a>
      </div>
    );
  }
}

export default Contact;
