import React, { Component } from "react";
import { FiGithub, FiGlobe, FiYoutube } from "react-icons/fi";
import { FaAppStore, FaGooglePlay, FaApple } from "react-icons/fa";
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import strings from "./localization";


var settings = {
  dots: false,
  infinite: true,
  speed: 250,
  slidesToShow: 1,
  slidesToScroll: 1
};

const projects = [
  {
    platform: "web",
    projects: [{
      title: strings.projects.web.projects.project_netflix.title,
      images: [
        "web/netflix/1.png", 
        "web/netflix/2.png", 
        "web/netflix/3.png", 
        "web/netflix/4.png", 
        "web/netflix/5.png"
      ], // 1400x615
      repository_url: "https://github.com/EyyupUlutas/netflix-clone",
      website_url: "https://netflix-clone-eyyupulutas.vercel.app",
      description: strings.projects.web.projects.project_netflix.description,
      technologies: ["React", "Tailwind", "HTML", "CSS", "JavaScript"],
    },
    {
      title: strings.projects.web.projects.project_challange_snake.title,
      images: [
        "web/challenge-snake/1.gif", 
        "web/challenge-snake/2.png", 
        "web/challenge-snake/3.png"
      ],
      repository_url: "https://github.com/EyyupUlutas/challenge-snake",
      website_url: "https://challengesnake.netlify.app",
      description: strings.projects.web.projects.project_challange_snake.description,
      technologies: ["HTML", "CSS", "JavaScript"],
      video_url: "https://youtu.be/gnfzINMXCyA?t=812",
    },
    {
      title: strings.projects.web.projects.project_bolita_coming.title,
      images: ["web/bolita-coming/bolita-coming.png"],
      website_url: "https://bolita-coming.vercel.app",
      description: strings.projects.web.projects.project_bolita_coming.description,
      technologies: ["HTML", "CSS", "JavaScript"],
    },
    {
      title: strings.projects.web.projects.project_bolita.title,
      images: ["web/bolita/bolita.png"],
      website_url: "https://bolita-web.vercel.app/home",
      description: strings.projects.web.projects.project_bolita.description,
      technologies: ["React", "Tailwind", "HTML", "CSS", "JavaScript", "Bootstrap", "Supabase"],
    },
    {
      title: strings.projects.web.projects.project_elrond_cyber.title,
      images: ["web/elrond-cyber/elrond-cyber.png"],
      website_url: "https://www.elrondcyber.com",
      description: strings.projects.web.projects.project_elrond_cyber.description,
      technologies: ["HTML", "CSS", "JavaScript"],
    },
    {
      title: strings.projects.web.projects.project_starbucks.title,
      images: ["web/starbucks/starbucks.png"],
      repository_url: "https://github.com/EyyupUlutas/starbucks-clone",
      website_url: "https://starbucks-clone-eyyupulutas.vercel.app",
      description: strings.projects.web.projects.project_starbucks.description,
      technologies: ["React", "Tailwind", "HTML", "CSS", "JavaScript"],
    },
    {
      title: strings.projects.web.projects.project_cebimdeki_dukkan.title,
      images: [
        "web/cebimdeki-dukkan/1.png", 
        "web/cebimdeki-dukkan/2.png", 
        "web/cebimdeki-dukkan/3.png", 
        "web/cebimdeki-dukkan/4.png", 
        "web/cebimdeki-dukkan/5.png", 
        "web/cebimdeki-dukkan/6.png"
      ],
      website_url: "https://cebimdekidukkan.com",
      description: strings.projects.web.projects.project_cebimdeki_dukkan.description,
      technologies: ["OpenCart", "PHP", "Cloudflare", "HTTP/3", "Journal 3"],
    },
    {
      title: strings.projects.web.projects.project_wash_center.title,
      images: [
        "web/car-wash/1.png", 
        "web/car-wash/2.png", 
        "web/car-wash/3.png", 
        "web/car-wash/4.png"
      ],
      website_url: "https://washcenter.vercel.app",
      description: strings.projects.web.projects.project_wash_center.description,
      technologies: ["NodeMCU", "ESP8266", "JavaScript", "React", "Tailwind", "Node.JS", "MySQL", "Vercel"],
    },
    {
      title: strings.projects.web.projects.project_kardesler_oto_servis.title,
      images: [
        "web/kardesler-oto-servis/1.png", 
        "web/kardesler-oto-servis/2.png", 
        "web/kardesler-oto-servis/3.png"
      ],
      website_url: "https://kardeslerotoservis.com",
      description: strings.projects.web.projects.project_kardesler_oto_servis.description,
      technologies: ["HTML", "CSS", "JavaScript"],
    }
  ]
  },

  //Mobil Projeler

  {
    platform: "mobile",
    projects: [{
      title: strings.projects.mobile.projects.project_cebimdeki_dukkan.title,
      images: [
        "mobile/cebimdeki-dukkan/1.png", 
        "mobile/cebimdeki-dukkan/2.png", 
        "mobile/cebimdeki-dukkan/3.png", 
        "mobile/cebimdeki-dukkan/4.png"
      ],
      app_store_urls: [
        {
          provider: "Google Play Store",
          url: "https://play.google.com/store/apps/details?id=com.cebimdekidukkan"
        }, 
        {
          provider: "App Store",
          url: "https://apps.apple.com/tr/app/cebimdeki-dükkan/id6447751522?l=tr"
        }
      ],
      description: strings.projects.mobile.projects.project_cebimdeki_dukkan.description,
      technologies: ["React Native"],
    },
    {
      title: strings.projects.mobile.projects.project_wit.title,
      images: [
        "mobile/wit/1.png", 
        "mobile/wit/2.png"
      ],
      app_store_urls: [
        {
          provider: "Google Play Store",
          url: "https://play.google.com/store/apps/details?id=com.witgame"
        }, 
        {
          provider: "App Store",
          url: "https://apps.apple.com/id/app/wit-fun-twitter-trivia-game/id1484772595"
        }
      ],
      description: strings.projects.mobile.projects.project_wit.description,
      technologies: ["React Native"],
    },
    {
      title: strings.projects.mobile.projects.project_adgain.title,
      images: [
        "mobile/adgain/1.png", 
        "mobile/adgain/2.png"
      ],
      app_store_urls: [
        {
          provider: "Google Play Store",
          url: "https://play.google.com/store/apps/details?id=com.adgain"
        }
      ],
      description: strings.projects.mobile.projects.project_adgain.description,
      technologies: ["React Native", "Firebase"],
    },
    {
      title: strings.projects.mobile.projects.project_meetzone_social.title,
      images: ["mobile/meetzone-social/1.png"],
      description: strings.projects.mobile.projects.project_meetzone_social.description,
      technologies: ["React Native"],
    },
    {
      title: strings.projects.mobile.projects.project_getir_clone.title,
      images: ["mobile/getir-clone/1.png"],
      description: strings.projects.mobile.projects.project_getir_clone.description,
      technologies: ["React Native"],
    },
    {
      title: strings.projects.mobile.projects.project_restaurant_app.title,
      images: [
        "mobile/restaurant-app/1.png", 
        "mobile/restaurant-app/2.png"
      ],
      description: strings.projects.mobile.projects.project_restaurant_app.description,
      technologies: ["React Native", "Node.JS", "JavaScript", "MySQL"],
    }
  ]
  }

];


class Projects extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: "all",
      tabs: [{
        id: "all",
        name: strings.projects_tabs.all
      },
      {
        id: "web",
        name: strings.projects_tabs.web
      }, {
        id: "mobile",
        name: strings.projects_tabs.mobile
      }],
      selected_platfrom_projects: [],
    }

  }


  componentDidMount() {
    const selectedTabId = this.state.selectedTabId;
    let allProjects = [
      ...projects[1].projects,
      ...projects[0].projects
    ]

    this.setState({ selected_platfrom_projects: projects[1].projects })
    if (selectedTabId == "all") {
      this.setState({ selected_platfrom_projects: allProjects })
    }
    else if (selectedTabId == "mobile") {
      this.setState({ selected_platfrom_projects: projects[1].projects })
    } else if (selectedTabId == "web") {
      this.setState({ selected_platfrom_projects: projects[0].projects })
    }

  }



  render() {
    let getPlatformProject = (platfrom) => {

      if (platfrom == "all") {
        let allProjects = [
          ...projects[1].projects,
          ...projects[0].projects
        ]
        this.setState({ selected_platfrom_projects: allProjects })

      }
      else {
        projects.forEach(project => {
          if (project.platform == platfrom) {
            this.setState({ selected_platfrom_projects: project.projects })
          }
        })
      }

    }

    return (
      <div
        id="projects"
        className="w-full flex flex-col justify-center items-center py-14 gap-y-14"
      >

        <div className="flex flex-col justify-center items-center gap-y-5 md:mb-7">
          <div className="text-primaryWhite text-4xl font-bold tracking-widest">
            {strings.projects_text}
          </div>
          <div className="h-1 w-8 bg-[#66ffdbd7] rounded-lg"></div>
          <div className="text-primaryWhite text-center px-2 md:px-0">
            {strings.projects_subtitle}
          </div>
        </div>


        <div className="flex w-full justify-center gap-x-5 mb-6 md:mb-12">
          {
            this.state.tabs.map(tab => (
              <button key={tab.id} onClick={() => {
                this.setState({ selectedTabId: tab.id })
                getPlatformProject(tab.id)
              }} className={`flex py-2 px-4 md:px-16 ${tab.id == this.state.selectedTabId ? "bg-[#66ffdbd7] text-[#020c1b]" : "bg-[#99999933] text-primaryWhite"} hover:ring-4 hover:ring-primaryWhite/20 font-semibold text-lg  md:text-xl rounded-custom`}>
                {tab.name}  {tab.id == "all" ? `(${[...projects[1].projects, ...projects[0].projects].length})` : tab.id == "web" ? `(${projects[0].projects.length})` : `(${projects[1].projects.length})`}
              </button>
            ))
          }
        </div>


        {this.state.selected_platfrom_projects.map((project, i) => {
          return (
            <div key={i} className="w-full flex flex-col md:flex-row justify-center px-0 md:px-3">
              <div className="flex  flex-1 justify-center">
                <Slider autoplay autoplaySpeed={2500} arrows={false} className="w-full md:w-[600px]" {...settings}>

                  {
                    project.images.map(img => (
                      <img
                        key={project.title}
                        src={require(`../assets/images/projects/${img}`)}
                        alt={project.title}
                        title={project.title}
                        className="w-[600px]  justify-center rounded-none md:rounded-md"
                      ></img>
                    ))
                  }
                </Slider>
              </div>

              <div className="flex flex-col flex-[0.9]  md:pr-24 justify-around px-3">
                <div>
                  <div className="text-primaryWhite text-2xl mt-3 md:mt-0 md:text-3xl font-semibold">
                    {project.title}
                  </div>
                  <div className="text-primaryWhite/80 text-sm md:text-base mt-4 md:mt-5">
                    {project.description}
                  </div>
                  <div className="flex flex-wrap gap-2 mt-4 md:mt-5">
                    {project.technologies.map((tech) => (
                      <div key={`${tech} ${Math.random() * 200}`} className="uppercase bg-[#99999933] text-primaryWhite px-2 py-2 rounded-custom  font-semibold text-xs select-none">
                        {tech}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="flex flex-row flex-wrap gap-y-3 gap-x-3 md:gap-x-6 mt-5 md:mt-0">
                  {project?.website_url && <a
                    target="_blank"
                    href={project.website_url}
                    className="flex rounded-custom bg-[#333] text-primaryWhite items-center px-4 md:px-14 py-3 gap-x-4 group hover:bg-primaryGreen/20 transition-all"
                  >
                    <FiGlobe className="text-primaryWhite  text-xl md:text-2xl group-hover:text-primaryGreen"></FiGlobe>
                    <div className="uppercase font-semibold md:text-lg group-hover:text-primaryGreen">
                      Website
                    </div>
                  </a>}

                  {project?.app_store_urls && (
                    project.app_store_urls.map(provider => (
                      <a
                        target="_blank"
                        href={provider.url}
                        className="flex rounded-custom bg-[#333] text-primaryWhite items-center px-4 md:px-14 py-3 gap-x-4 group hover:bg-primaryGreen/20 transition-all"
                      >
                        {
                          provider.provider == "App Store" ?
                            <>
                              <FaApple className="text-primaryWhite  text-xl md:text-2xl group-hover:text-primaryGreen"></FaApple>
                              <div className="uppercase font-semibold md:text-lg group-hover:text-primaryGreen">
                                App Store
                              </div>
                            </>
                            : provider.provider == "Google Play Store" ? <>
                              <FaGooglePlay className="text-primaryWhite  text-xl md:text-2xl group-hover:text-primaryGreen"></FaGooglePlay>
                              <div className="uppercase font-semibold md:text-lg group-hover:text-primaryGreen">
                                Google Play
                              </div>
                            </> : ""
                        }
                      </a>
                    ))
                  )}
                  {project.repository_url && (
                    <a
                      target="_blank"
                      href={project.repository_url}
                      className="flex rounded-custom bg-[#333] text-primaryWhite items-center px-4 md:px-14 py-3 gap-x-4 group hover:bg-primaryGreen/20 transition-all"
                    >
                      <FiGithub className="text-primaryWhite  text-xl md:text-2xl group-hover:text-primaryGreen"></FiGithub>
                      <div className="uppercase font-semibold md:text-lg group-hover:text-primaryGreen">
                        Github
                      </div>
                    </a>
                  )}
                  {project.video_url && (
                    <a
                      target="_blank"
                      href={project.video_url}
                      className="flex rounded-custom bg-[#FF0000]/90 text-primaryWhite items-center px-5 py-3 gap-x-4 group  transition-all"
                    >
                      <FiYoutube className="text-primaryWhite  text-2xl "></FiYoutube>
                    </a>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Projects;
