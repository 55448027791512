import React, { Component } from "react";
import { stack as Menu } from "react-burger-menu";
import { FiMenu } from "react-icons/fi";
import pp from "../assets/images/eyyup.jpg";
import strings from './localization';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  render() {
    var styles = {
      bmBurgerButton: {
        position: "fixed",
        width: "36px",
        height: "30px",
        right: "36px",
        top: "25px",
      },

      bmBurgerBarsHover: {
        background: "#a90000",
      },
      bmCrossButton: {
        height: "24px",
        width: "24px",
      },
      bmCross: {
        background: "#bdc3c7",
      },
      bmMenuWrap: {
        position: "fixed",
        height: "100%",
      },
      bmMenu: {
        background: "#020c1b",
        padding: "2.5em 1.5em 0",
        fontSize: "1.15em",
        display: "flex",
        justifyContent: "center",
      },

      bmItemList: {
        color: "#ffff",
        padding: "0.8em",
      },
      bmItem: {
        display: "flex",
        justifyContent: "center",
      },
      bmOverlay: {
        background: "rgba(0, 0, 0, 0.3)",
      },
    };

    const handleClickScroll = (id) => {
      if (id) {
        switch (id) {
          case 1:
            if (window) {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }
            break;
          case 2: //Hakkımda
            const about = document.getElementById("about");
            if (about) {
              about.scrollIntoView({ behavior: "smooth" });
            }
            break;
          case 3:
            const projects = document.getElementById("projects");
            if (projects) {
              projects.scrollIntoView({ behavior: "smooth" });
            }
            break;
          case 4:
            const contact = document.getElementById("contact");
            if (contact) {
              contact.scrollIntoView({ behavior: "smooth" });
            }
            break;

          default:
            break;
        }
      }
    };
    return (
      <div className="flex fixed z-50 w-full flex-row justify-between items-center px-8 md:px-16  bg-[#020c1b] text-primaryWhite">
        <div className="flex flex-row h-20">
          <div className="flex flex-1 items-center  gap-x-4">
            <img className="w-12 h-12 rounded-full" src={pp}></img>
            <div className="font-semibold tracking-wider text-lg text-center uppercase">
              Eyyüp Ulutaş
            </div>
          </div>
          <div className="md:hidden">
            <Menu
              customBurgerIcon={<FiMenu size={50}></FiMenu>}
              onStateChange={(isOpen) => {
                this.setState({ isOpen: isOpen.isOpen });
              }}
              isOpen={this.state.isOpen}
              width={"100%"}
              right
              styles={styles}
            >
              <div className="w-full text-center flex flex-col justify-center items-center ">
                <img className="w-32 h-32 rounded-full" src={pp}></img>
                <div className="flex flex-col font-semibold tracking-wider text-2xl text-center uppercase mt-5">
                  <span>Eyyüp Ulutaş</span>
                  <span className="text-xs text-primaryWhite/80">
                    {strings.header_title}
                  </span>
                </div>
              </div>
              <div className="flex flex-col items-center gap-y-4 mt-16 text-2xl">
                <button
                  onClick={() => {
                    this.setState({ isOpen: false });
                    handleClickScroll(1);
                  }}
                  className="font-semibold  hover:text-primaryGreen  hover:cursor-pointer"
                >
                  {strings.header_home}
                </button>
                <button
                  onClick={() => {
                    this.setState({ isOpen: false });
                    handleClickScroll(2);
                  }}
                  className="font-semibold hover:text-primaryGreen hover:cursor-pointer"
                >
                  {strings.header_about}
                </button>
                <button
                  onClick={() => {
                    this.setState({ isOpen: false });
                    handleClickScroll(3);
                  }}
                  className="font-semibold hover:text-primaryGreen hover:cursor-pointer"
                >
                  {strings.header_projects}
                </button>
                <button
                  onClick={() => {
                    this.setState({ isOpen: false });
                    handleClickScroll(4);
                  }}
                  className="font-semibold hover:text-primaryGreen hover:cursor-pointer"
                >
                  {strings.header_communication}
                </button>
              </div>

              <div className="absolute left-0 bottom-2 text-[10px] w-full text-primaryWhite/30">
                www.eyyupulutas.com
              </div>
            </Menu>
          </div>
        </div>

        {/* PC Header */}
        <div className="hidden md:flex flex-1 justify-end gap-x-12  px-5 ">
          <button
            onClick={() => {
              handleClickScroll(1);
            }}
            className="font-semibold hover:text-primaryGreen  hover:cursor-pointer"
          >
            {strings.header_home}
          </button>
          <button
            onClick={() => {
              handleClickScroll(2);
            }}
            className="font-semibold hover:text-primaryGreen hover:cursor-pointer"
          >
            {strings.header_about}
          </button>
          <button
            onClick={() => {
              handleClickScroll(3);
            }}
            className="font-semibold hover:text-primaryGreen hover:cursor-pointer"
          >
            {strings.header_projects}
          </button>
          <button
            onClick={() => {
              handleClickScroll(4);
            }}
            className="font-semibold hover:text-primaryGreen hover:cursor-pointer"
          >
            {strings.header_communication}
          </button>
        </div>
      </div>
      // <div className="flex fixed z-50 w-full flex-row justify-between items-center px-8 md:px-16 h-20 bg-[#020c1b] text-primaryWhite">
      //   <div className="flex flex-1 items-center  gap-x-4">
      //     <img
      //       className="w-12 h-12 rounded-full"
      //       src="https://media.licdn.com/dms/image/D4D03AQFHt0D0_BKNqQ/profile-displayphoto-shrink_400_400/0/1671988846984?e=1681344000&v=beta&t=jO7QUSxln1yqzrMCXA8RbC_o9xXMtARdGwG-PY6oQQ0"
      //     ></img>
      //     <div className="font-semibold tracking-wider text-lg text-center uppercase">
      //       Eyyüp Ulutaş
      //     </div>
      //   </div>

      //   {/* <div className="hidden md:flex flex-1 justify-end gap-x-12  px-5 ">
      //     <button
      //       onClick={() => {
      //         handleClickScroll(1);
      //       }}
      //       className="font-semibold hover:text-primaryGreen  hover:cursor-pointer"
      //     >
      //       Anasayfa
      //     </button>
      //     <button
      //       onClick={() => {
      //         handleClickScroll(2);
      //       }}
      //       className="font-semibold hover:text-primaryGreen hover:cursor-pointer"
      //     >
      //       Hakkımda
      //     </button>
      //     <button
      //       onClick={() => {
      //         handleClickScroll(3);
      //       }}
      //       className="font-semibold hover:text-primaryGreen hover:cursor-pointer"
      //     >
      //       Projeler
      //     </button>
      //     <button
      //       onClick={() => {
      //         handleClickScroll(4);
      //       }}
      //       className="font-semibold hover:text-primaryGreen hover:cursor-pointer"
      //     >
      //       İletişim
      //     </button>
      //   </div> */}
      // </div>
    );
  }
}

export default Header;
