import React, { Component } from "react";
import strings from './localization';

class About extends Component {
  render() {
    const skills = [
      "React",
      "JavaScript",
      "React Native",
      "Node.JS",
      "MySQL",
      "HTML5",
      "CSS3",
      "C#",
      "Arduino",
      "WordPress",
      "Microsoft Office",
      "Database",
      "Web Development",
      "Computer Hardware",
      "Computer Programs",
      "Software",
      "Mobile Software",
    ];    
    const handleClickScroll = () => {
      const element = document.getElementById("contact");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    return (
      <div
        id="about"
        className="w-full flex flex-col justify-center items-center py-14 gap-y-14 "
      >
        <div className="flex flex-col justify-center items-center gap-y-5 md:mb-14">
          <div className="text-primaryWhite text-4xl font-bold tracking-widest">
            {strings.about_title}
          </div>
          <div className="h-1 w-8 bg-[#66ffdbd7] rounded-lg"></div>
          <div className="text-primaryWhite text-center px-2 md:px-0">
            {strings.about_subtitle}
          </div>
        </div>

        <div className="flex flex-col md:flex-row w-full ">
          <div>
            <div className="flex-1 px-6 md:pl-24 text-3xl text-primaryWhite font-semibold pb-5  md:pb-10">
              {strings.about_know_me}
            </div>

            <div className="flex flex-col flex-1 px-6 md:pl-24">
              <div className="flex flex-col gap-y-5">
                <div className="text-primaryWhite/80 md:text-lg ">
                  {strings.about_me_1}
                </div>

                <div className="text-primaryWhite/80 md:text-lg ">
                  {strings.about_me_2}
                </div>

                <div className="text-primaryWhite/80 md:text-lg ">
                  {strings.about_me_3}
                </div>
              </div>

              <button
                onClick={handleClickScroll}
                className="uppercase mt-10 mb-12 md:mb-0 w-[50%] md:w-[25%] font-semibold md:text-lg bg-[#66ffdbd7] text-[#020c1b]   flex  items-center justify-center text-center px-10  py-2.5 rounded-custom hover:ring-4 hover:ring-primaryWhite/20 hover:cursor-pointer transition-all"
              >
                {strings.about_communication}
              </button>
            </div>
          </div>

          <div>
            <div className="flex-1 px-6 md:pl-24 text-3xl text-primaryWhite font-semibold pb-5 md:pb-10">
              {strings.about_abilities_interests}
            </div>

            <div className="flex flex-1 items-start justify-start px-6 md:pl-24">
              <div className="flex flex-wrap gap-3 md:gap-5">
                {skills.map((skill) => (
                  <div key={skill} className="bg-[#99999933] text-primaryWhite px-4 py-2 rounded-custom text-sm md:text-base  font-semibold select-none">
                    {skill}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
