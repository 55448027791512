import React, { Component } from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import About from "./components/About";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import { Analytics } from '@vercel/analytics/react';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
    };
  }

  componentDidMount() {
    const handleResize = () => {
      if (window.innerWidth < 720) {
        this.setState({ isMobile: true });
      } else {
        this.setState({ isMobile: false });
      }
    };

    window.addEventListener("resize", handleResize);
  }

  render() {
    return (
      <div>
        {/* {this.state.isMobile &&} */}
        <Header></Header>
        <div>
          <Hero></Hero>
          <About></About>
          <Projects></Projects>
          <Contact></Contact>
        </div>
        <Footer></Footer>
        <Analytics />

      </div>
    );
  }
}

export default App;
